/* global HTMLMediaElement */
/* eslint-disable no-console */

import envDefault from './env';

// This is a subset of all possible HTMLMediaElement events. We leave some because we
// either don't care about it (`encrypted`) or they might be too noisy (e.g., `timeupdate`).
const mediaElementEvents = [
  'abort', 'canplay', 'canplaythrough', 'durationchange', 'emptied', 'ended', 'error', 'loadeddata',
  'loadedmetadata', 'loadstart', 'pause', 'play', 'playing', 'progress', 'ratechange', 'seeked',
  'seeking', 'stalled', 'suspend', 'volumechange', 'waiting',
];

const addMediaElementListeners = (mediaElement, triggerEvent, deps = {}) => {
  const env = deps.env || envDefault;
  if (!env?.isDev) {
    console.debug("Not in DEV, so won't add debug listeners");
    return;
  }

  if (!(mediaElement instanceof HTMLMediaElement)) {
    console.error('addMediaElementListeners: Invalid HTMLMediaElement');
    return;
  }

  mediaElementEvents.forEach((event) => {
    mediaElement.addEventListener(event, (e) => {
      triggerEvent(`mediaElement:${event}`);
      console.debug(`HTMLMediaElement triggered: ${event}`, e);
    });
  });
};

export default addMediaElementListeners;
